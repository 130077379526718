<template>
  <div
    :class="classes"
    class="w-timer"
  >
    <div class="w-timer__icon">
      <slot name="icon">
        <v-icon
          name="clock"
          stroke="primary"
        />
      </slot>
    </div>

    <div class="w-timer__content">
      <slot name="default">
        <template v-if="isLimitless">
          {{ $t('widget.timer.defaultText') }}
        </template>

        <template v-else-if="isPending">
          <v-progress-circular
            :value="30"
            :width="2"
            :size="22"
            indeterminate
          />
        </template>

        <template v-else>
          <span v-if="hours">{{ hours | formatTime }}:</span><span>{{ minutes | formatTime }}:{{
            seconds | formatTime
          }}</span>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import {
  VIcon,
  VProgressCircular
} from '@components/base'

export default {
  name: 'WTimer',

  components: {
    VIcon,
    VProgressCircular
  },

  filters: {
    formatTime: value => value < 10 ? `0${value}` : value
  },

  props: {
    serverTime: {
      type: Number,
      default: Math.floor(+new Date() / 1000)
    },
    start: {
      type: Number,
      default: 0
    },
    end: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      time: {
        lag: this.doCorrection(),
        current: null
      },
      timer: {
        id: null,
        delay: 1000
      },
      isPending: false,
      isLimitless: this.end === this.start
    }
  },

  computed: {
    classes () {
      return {
        'w-timer_is_pending': this.isPending,
        'w-timer_is_limitless': this.isLimitless
      }
    },
    seconds () {
      return Math.floor(this.time.current % 60)
    },
    minutes () {
      return Math.floor((this.time.current / 60) % 60)
    },
    hours () {
      return Math.floor((this.time.current / 3600) % 24)
    }
  },

  watch: {
    serverTime () {
      this.refreshLimitless()
      this.resetTimer()
    },

    end () {
      this.refreshLimitless()
      this.resetTimer()
    }
  },

  mounted () {
    this.refreshLimitless()

    // обработка случая кода вкладка была закрыта
    // и открыта уже после истечения времени на текущем задании
    if (this.serverTime > this.end) {
      this.endTimer()
      return
    }

    this.startTimer()
  },

  destroyed () {
    this.stopTimer()
  },

  methods: {
    /**
     * Включить таймер
     */
    startTimer () {
      if (this.isLimitless) return

      this.isPending = false
      this.time.current = this.getCurrentTime()

      this.timer.id = setInterval(() => {
        this.time.current = this.getCurrentTime()

        if (this.time.current <= 0) {
          this.endTimer()
        }
      }, this.timer.delay)
    },

    /**
     * Перезапустить таймер
     */
    resetTimer () {
      this.isPending = true

      this.time.lag = this.doCorrection()
      this.stopTimer()
      this.startTimer()

      this.isPending = false
    },

    /**
     * Остановить таймер
     */
    stopTimer () {
      if (this.timer.id === null) return

      clearInterval(this.timer.id)
      this.timer.id = null
      this.time.current = null
    },

    /**
     * Действия при завершении таймера
     */
    endTimer () {
      this.stopTimer()
      this.isPending = true
      this.$emit('end')
    },

    /**
     * Синхронизация серверного и текущего времени
     * @return {number} - разница между текущи и сервернам временем
     */
    doCorrection () {
      return Math.floor((+new Date() / 1000) - this.serverTime)
    },

    /**
     * Расчёт оставшегося времени
     * @return {number} - оствшееся время в ms
     */
    getCurrentTime () {
      return this.end - Math.floor(+new Date() / 1000) + this.time.lag
    },

    refreshLimitless () {
      this.isLimitless = this.end === this.start
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~@styles/tools";
@import "~bootstrap/scss/mixins/breakpoints";

.w-timer {
  display: flex;
  align-items: center;
  width: auto;
  font-weight: 600;
  color: cl(primary);

  &__icon {
    > .v-icon {
      position: relative;
      top: -1px;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: .5rem;

      @include media-breakpoint-down(sm) {
        top: -3px;
        width: 1.2rem;
        height: 1.2rem;
        margin-right: .2rem;
      }
    }
  }

  &__content {
    font-size: 1.5rem;
    line-height: 1;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  &_is {
    &_pending,
    &_limitless {
      opacity: .5;

      .w-timer__content {
        font-size: 1rem;
      }
    }
  }
}
</style>
