<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col-12 col-xl-3 order-xl-2 mb-24 sticky">
        <v-card class="overflow-hidden sticky">
          <l-widget>
            <template #header>
              <w-timer
                :start="widget.timer.start"
                :end="widget.timer.end"
                :server-time="widget.timer.current"
              />
            </template>

            <template #default>
              <w-progress
                :progress="widget.progress"
              />
            </template>
          </l-widget>
        </v-card>
      </div>

      <div class="col-12 col-xl-9 order-xl-1">
        <v-card class="py-32 px-16 p-xl-32">
          <p>
            В детском лагере вожатый проводит с детьми утреннюю зарядку,
            и вся группа постепенно просыпается. Команды ведущего выполняются,
            у детей повышается настроение. Неожиданно один мальчик громко заявляет:
            «Зачем мы все это делаем? Я хочу спать. Вы меня заставляете,
            вы нарушаете мои права». Детям около 15-16 лет. Остальные прекратили
            выполнять упражнения и с интересом смотрят, как отреагирует вожатый,
            это определенный вызов. Просто продолжите нельзя — дети ждут от вожатого ответа.
          </p>

          <p class="mb-32">
            <b>
              Оцените эффективность действий, которые вожатый можент предпринять
              в данной ситуации.
            </b>
          </p>

          <div
            class="row mb-16"
            v-for="answerItem in answers"
            :key="answerItem.text"
          >
            <div class="col">
              <v-card-button
                :ripple="false"
                :active="!!answerItem.answer"
              >
                <div class="row mb-16">
                  <div class="col">
                    {{ answerItem.text }}
                  </div>
                </div>

                <div class="row mx-n4 mb-n4">
                  <div
                    class="col-12 col-lg-6 p-4"
                    v-for="answerOption in answerItem.options"
                    :key="answerOption"
                  >
                    <v-card-button @click="answerItem.answer = answerOption">
                      <template #selector="{ events: { toggleActive } }">
                        <v-radio
                          :model-value="answerItem.answer"
                          :value="answerOption"
                          @change="toggleActive"
                        ></v-radio>
                      </template>

                      <template #default>
                        {{ answerOption }}
                      </template>
                    </v-card-button>
                  </div>
                </div>
              </v-card-button>
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button @click="onClick">
                Ответить
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VButton,
  VCard,
  VCardButton,
  VRadio
} from '@components/base'
import { WTimer, WProgress } from '@components/widgets'
import { LWidget } from '@/layouts'

export default {
  name: 'SRadioTable',

  components: {
    LWidget,
    VButton,
    VCard,
    VCardButton,
    VRadio,
    WProgress,
    WTimer
  },

  data () {
    return {
      widget: {
        progress: {
          total: 3,
          current: 1
        },
        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 120
        }
      },
      answers: [
        {
          text: 'Заставить его выйти на место ведущего и показать более приемлемые, на его взгляд, упражнения. Пояснить, что он своим поведением тормозит работу всей группы, хотя вопрос является личным и его нужно обсуждать во время перерыва',
          answer: null,
          options: [
            'Неэффективно',
            'Скорее неэфективно',
            'Скорее эффективно',
            'Эффективно'
          ]
        },
        {
          text: 'Встречно сделать неожиданный для детей шаг — позволить нарушителю спокойно идти и спать, взяв с него обещание, что он будет именно спать, а не заниматься посторонними делами',
          answer: null,
          options: [
            'Неэффективно',
            'Скорее неэфективно',
            'Скорее эффективно',
            'Эффективно'
          ]
        },
        {
          text: 'Показать знание закона — процитировать статью устава лагеря, где указаны условия контракта между организаторами лагеря и родителями и обговорено расписание.',
          answer: null,
          options: [
            'Неэффективно',
            'Скорее неэфективно',
            'Скорее эффективно',
            'Эффективно'
          ]
        }
      ]
    }
  },

  methods: {
    onClick () {
      console.log('ответ', this.answers.map((answer, i) => `${i} - ${answer.answer}`))
    }
  }
}
</script>
